import React from 'react';
import '../../App.css';
import Footer from '../Footer';
import PageHeader from '../PageHeader';
import Menu2 from '../Menu2';
import {Link} from "react-router-dom";

function Profile() {

    return (
        <div className='container-fluid'>
            <div className="row">
                <div className='col-md-12'>
                    <PageHeader />
                </div>
            </div>
            <div className="row">
                <div className='col-md-2'>
                    <Menu2 />
                </div>
                <div className='col-md-10 pageContent'>
                    <div className="row">
                        <div className="row shadow col-md-11 ml-3 mb-3 mr-3 bg-white shadow rounded">
                            <div className="breadcrumb"><Link to={"/portal"}>Home</Link> > <Link to={"/account"}>Account</Link> > Personal Info</div>
                            <p>
                                <h4>Update Address</h4>

                                Coming soon
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className='col-md-12'>
                    <Footer />
                </div>
            </div>
        </div>
    );
}
export default Profile;


