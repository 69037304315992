import React from 'react';
import '../App.css';
import Footer from './Footer';
import PageHeader from './PageHeader';
import Menu2 from './Menu2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import { faBell } from "@fortawesome/free-regular-svg-icons";

function Editor1() {

    return (
        <div className='container-fluid'>
            <div className="row">
                <div className='col-md-12'>
                    <PageHeader/>
                </div>
            </div>
            <div className="row">
                <div className='col-md-2'>
                    <Menu2 />
                </div>
                <div className='col-md-10 pageContent'>

                    <div className="row">
                        <div className="col-md-1 bg-light border border-dark text-center">
                            <FontAwesomeIcon icon={faCircleInfo} data-tooltip-id="icon-tooltip-traffic" data-tooltip-content="Test" data-tooltip-place="top" className="editorIcon" /><br/>
                            <FontAwesomeIcon icon={faBell} data-tooltip-id="icon-tooltip-traffic" data-tooltip-content="Test" data-tooltip-place="top" className="editorIcon" /><br/>
                            <FontAwesomeIcon icon={faCircleInfo} data-tooltip-id="icon-tooltip-traffic" data-tooltip-content="Test" data-tooltip-place="top" className="editorIcon" /><br/>
                            <FontAwesomeIcon icon={faCircleInfo} data-tooltip-id="icon-tooltip-traffic" data-tooltip-content="Test" data-tooltip-place="top" className="editorIcon" /><br/>
                            <FontAwesomeIcon icon={faCircleInfo} data-tooltip-id="icon-tooltip-traffic" data-tooltip-content="Test" data-tooltip-place="top" className="editorIcon" /><br/>
                            <FontAwesomeIcon icon={faCircleInfo} data-tooltip-id="icon-tooltip-traffic" data-tooltip-content="Test" data-tooltip-place="top" className="editorIcon" /><br/>
                            <FontAwesomeIcon icon={faCircleInfo} data-tooltip-id="icon-tooltip-traffic" data-tooltip-content="Test" data-tooltip-place="top" className="editorIcon" /><br/>
                            <FontAwesomeIcon icon={faCircleInfo} data-tooltip-id="icon-tooltip-traffic" data-tooltip-content="Test" data-tooltip-place="top" className="editorIcon" /><br/>
                            <FontAwesomeIcon icon={faCircleInfo} data-tooltip-id="icon-tooltip-traffic" data-tooltip-content="Test" data-tooltip-place="top" className="editorIcon" /><br/>
                            <FontAwesomeIcon icon={faCircleInfo} data-tooltip-id="icon-tooltip-traffic" data-tooltip-content="Test" data-tooltip-place="top" className="editorIcon" /><br/>
                            <FontAwesomeIcon icon={faCircleInfo} data-tooltip-id="icon-tooltip-traffic" data-tooltip-content="Test" data-tooltip-place="top" className="editorIcon" /><br/>
                            <FontAwesomeIcon icon={faCircleInfo} data-tooltip-id="icon-tooltip-traffic" data-tooltip-content="Test" data-tooltip-place="top" className="editorIcon" /><br/>

                        </div>
                        <div className="col-md-10 bg-white">

                        </div>
                    </div>


                </div>
            </div>
            <div className="row">
                <div className='col-md-12'>
                    <Footer/>
                </div>
            </div>
        </div>
    );
}

export default Editor1;

