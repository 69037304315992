import React, {useState} from 'react';
import '../App.css';
import Footer from './Footer';
import PageHeader from './PageHeader';
import Menu2 from './Menu2';
import {Link} from "react-router-dom";
import Cookies from "universal-cookie";
import axios from "axios";
import { useDropzone } from 'react-dropzone';
import { SpinnerComponent } from 'react-element-spinner';
import { ToastContainer, toast, Bounce } from 'react-toastify';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircleInfo, faTriangleExclamation} from "@fortawesome/free-solid-svg-icons";
import 'react-toastify/dist/ReactToastify.css';
import DOMPurify from "dompurify";


function FilesUpload() {
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState([]);
    const cookies = new Cookies();
    const session_id = cookies.get('session_id');
    const allowedTypes = ['image/bmp','image/cmu-raster','image/fif','image/florian','image/g3fax','image/gif',
        'image/ief','image/jpeg','image/jutvision','image/naplps','image/pict','image/pjpeg','image/png','image/svg+xml',
        'image/tiff','image/vasa','image/vnd.dwg','image/vnd.fpx','image/vnd.net-fpx','image/vnd.rn-realflash','image/vnd.rn-realpix',
        'image/vnd.wap.wbmp','image/vnd.xiff','image/webp', 'image/x-cmu-raster', 'image/x-dwg', 'image/x-icon',
        'image/x-jg', 'image/x-jps', 'image/x-ms-bmp','image/x-niff', 'image/x-pcx', 'image/x-pict', 'image/x-portable-anymap',
        'image/x-portable-bitmap', 'image/x-portable-graymap', 'image/x-portable-pixmap', 'image/x-quicktime','image/x-rgb',
        'image/x-tiff', 'image/x-windows-bmp', 'image/x-xbitmap', 'image/x-xbm', 'image/x-xpixmap', 'image/x-xwd', 'image/x-xwindowdump',
        'image/xbm', 'image/xpm', 'application/pdf', 'video/animaflex', 'video/avi', 'video/avs-video', 'video/dl', 'video/fli',
        'video/gl', 'video/mp2t', 'video/mp4', 'video/mpeg', 'video/msvideo', 'video/ogg', 'video/quicktime', 'video/vdo',
        'video/vivo', 'video/vnd.rn-realvideo', 'video/vnd.vivo', 'video/vosaic', 'video/webm', 'video/x-amt-demorun', 'video/x-amt-showrun', 'video/x-atomic3d-feature',
        'video/x-dl', 'video/x-dv', 'video/x-fli', 'video/x-flv', 'video/x-gl', 'video/x-isvideo', 'video/x-m4v', 'video/x-matroska',
        'video/x-motion-jpeg', 'video/x-mpeg', 'video/x-mpeq2a', 'video/x-ms-asf', 'video/x-ms-asf-plugin', 'video/x-ms-wmv', 'video/x-msvideo', 'video/x-qtc',
        'video/x-scm', 'video/x-sgi-movie', 'audio/mpeg', 'audio/mpeg3', 'audio/x-wav', 'audio/ogg', 'audio/x-flac','audio/x-ms-wma','audio/x-aiff']

    const uploadFiles = (acceptedFiles) => {
        setLoading(true);
        setErrors([]);
        for (let i = 0; i < acceptedFiles.length; i++) {
            (async () => {
                const file = acceptedFiles[i];

                if(allowedTypes.includes(file.type)) {
                    const presignedData = await axios
                        .post(`${process.env.REACT_APP_API_DOMAIN}/api/awsurl`,{
                            'fileName' : file.name,
                            'fileType' : file.type
                        }, {
                            withCredentials: true,
                            headers: {
                                Authorization: session_id
                            }
                        });

                    if(presignedData.data.url) {

                        const newFile = new File([file], presignedData.data.fileName, {
                            type: file.type,
                        });

                        await axios.put(presignedData.data.url, newFile, {
                            headers: {
                                "Content-Type": file.type,
                            },
                        });

                        await axios.post(`${process.env.REACT_APP_API_DOMAIN}/api/media/`, {
                                'fileName' : presignedData.data.fileName,
                                'size' : newFile.size
                            },
                            {
                                withCredentials: true,
                                headers: {
                                    Authorization: session_id
                                }
                            });
                    }
                    setLoading(false);
                    toast("Your file has been uploaded");
                } else {
                    setLoading(false);
                    setErrors([{'message': "The file type " + file.type + " is not allowed. If you believe this is a mistake, please contact us."}]);
                }
            })();
        }
    }

    function Message(props) {
        return <div className="alert alert-danger"><FontAwesomeIcon icon={faTriangleExclamation} className='menuIcon'/>{DOMPurify.sanitize(props.message)}</div>;
    }

    const errorMessages = errors.map((error, k) => <Message message={error.message} link={error.link} key={k}/>);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop: uploadFiles,
        multiple: false,
    });

    return (
        <div className='container-fluid'>
            <div className="row">
                <div className='col-md-12'>
                    <PageHeader />
                </div>
            </div>
            <div className="row">
                <div className='col-md-2'>
                    <Menu2 />
                </div>
                <div className='col-md-10 pageContent'>
                    <div className="row">
                        <div className="row shadow col-md-11 ml-3 mb-3 mr-3 bg-white shadow rounded">
                            <div className="breadcrumb"><Link to={"/portal"}>Home</Link> > Submit File</div>
                            <SpinnerComponent loading={loading} position="global" color="#498DFF" message={"Sit tight"}/>
                            <ToastContainer
                                position="top-center"
                                autoClose={5000}
                                hideProgressBar={false}
                                newestOnTop={false}
                                closeOnClick
                                rtl={false}
                                pauseOnFocusLoss
                                draggable
                                pauseOnHover
                                theme="dark"
                                transition={Bounce}
                            />
                            <p>
                                <div className="px-2">
                                    <div>{errorMessages}</div>
                                    <p>Sending media files to Agency Next is easy. Simply drag a file into the area below or click it to choose a file on your computer.</p>
                                    <p>If you add your mobile phone to your <Link to={"/account"}>profile</Link>, you may also send the file as a text message to {process.env.REACT_APP_FILE_UPLOAD_NUMBER}. One file per message please.</p>

                                    <section className="container">
                                        <div className="upload-container">
                                            <div {...getRootProps()} className={`dropzone ${isDragActive ? 'active' : ''} p-4 border border-2 border-primary-subtle rounded bg-light justify-content-center`}>
                                                <input {...getInputProps()} />
                                                {isDragActive ? (<p>Drop the file here</p>) : (<p><FontAwesomeIcon className="info-icon" icon={faCircleInfo} /> Drag and drop file here or click to browse</p>)}
                                            </div>
                                        </div>
                                    </section>

                                    <p><Link to={"/files-submitted"}>See all your uploaded files.</Link></p>

                                </div>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className='col-md-12'>
                    <Footer />
                </div>
            </div>
        </div>
    );
}
export default FilesUpload;

