import React, {useEffect, useState} from 'react';
import '../App.css';
import Footer from './Footer';
import PageHeader from './PageHeader';
import Menu2 from './Menu2';
import axios from "axios";
import DOMPurify from "dompurify";
import {Link, useNavigate, useParams} from 'react-router-dom';
import Cookies from "universal-cookie";
import { SpinnerComponent } from 'react-element-spinner';

function NewsDetail() {
    const [posts, setPosts] = useState([]);
    const { id } = useParams();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const cookies = new Cookies();
        const session_id = cookies.get('session_id');

        const fetchData = async () => {
            setLoading(true);
            const postData = await axios
                .get(`${process.env.REACT_APP_API_DOMAIN}/api/news/${id}`, {
                    withCredentials: true,
                    headers: {
                        Authorization: session_id
                    }
                });
            setPosts(postData.data);
            setLoading(false);
        };
        void fetchData();
    }, [id, navigate]);

    function Post(props) {
        const sitename = props.data._sitename ? '[' + DOMPurify.sanitize(props.data._sitename) + '] ' : '';
        return <li className="rssPost">
            {sitename}<Link to={DOMPurify.sanitize(props.data._url)} target="_blank">{DOMPurify.sanitize(props.data._title)}</Link><br />
            <small>Posted: {props.data._published_date} {DOMPurify.sanitize(props.data._url)}</small><br/>
            <small>{DOMPurify.sanitize(props.data._description)}</small>
        </li>
    }

    const feed =
        posts.map((post, k) => <Post data={post} key={k} />);


    return (
        <div className='container-fluid'>
            <div className="row">
                <div className='col-md-12'>
                    <PageHeader />
                </div>
            </div>
            <div className="row">
                <div className='col-md-2'>
                    <Menu2 />
                </div>
                <div className='col-md-10 pageContent'>
                    <div className="row">
                        <div className="row shadow col-md-11 ml-3 mb-3 mr-3 bg-white shadow rounded">
                            <div className="breadcrumb"><Link to={"/portal"}>Home</Link> > <Link to={"/news-sources"}>News Sources</Link> > SITE NAME</div>
                            <SpinnerComponent loading={loading} position="global" color="#498DFF" message={"Sit tight"}/>
                            <p>
                                <h4>SITE NAME GOES HERE</h4>

                                <p><ul>{feed}</ul></p>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className='col-md-12'>
                    <Footer />
                </div>
            </div>
        </div>
    );
}
export default NewsDetail;

