import React, {useState} from 'react';
import '../../App.css';
import Footer from '../Footer';
import PageHeader from '../PageHeader';
import Menu2 from '../Menu2';
import {Link} from "react-router-dom";
import {SpinnerComponent} from "react-element-spinner";
import Cookies from "universal-cookie";
import axios from "axios";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTriangleExclamation} from "@fortawesome/free-solid-svg-icons";
import DOMPurify from "dompurify";



function Introduction() {


    const [_name, setName] = useState('');
    const [_industry, setIndustry] = useState('');
    const [_experience, setExperience] = useState('');
    const [_location, setLocation] = useState('');
    const [_script, setScript] = useState('');

    const [isDisabled, setDisabled] = useState();
    const [errors, setErrors] = useState([]);
    const [loading, setLoading] = useState(false);

    const onSubmit = async (e) => {
        setLoading(true);
        e.preventDefault();
        setDisabled(true);

        const cookies = new Cookies();
        const session_id = cookies.get('session_id');

        const params = {
            '_name' : _name,
            '_industry' : _industry,
            '_experience' : _experience,
            '_location' : _location,
            '_type' : 'introduction'
        }

        const response = await axios
            .post(`${process.env.REACT_APP_API_DOMAIN}/api/message/script/generate/`, params, {
                withCredentials: true,
                headers: {
                    Authorization: session_id
                }
            })
            .catch((error) => {
                setDisabled(false);

                if(error.response && error.response && error.response.data) {
                    setErrors(error.response.data.notifications);
                } else {
                    setErrors([{'message': 'An error occurred generating the script. Please try again','link': ''}]);
                }
            });

        setScript(response.data.script);
        setDisabled(false);
        setLoading(false);
    };

    function Message(props) {
        return <div  className="alert alert-danger"><FontAwesomeIcon icon={faTriangleExclamation} className='menuIcon' />{DOMPurify.sanitize(props.message)}</div>;
    }

    const errorMessages =
        errors.map((error, k) => <Message message={error} key={k} />);

    return (
        <div className='container-fluid'>
            <div className="row">
                <div className='col-md-12'>
                    <PageHeader />
                </div>
            </div>
            <div className="row">
                <div className='col-md-2'>
                    <Menu2 />
                </div>
                <div className='col-md-10 pageContent'>
                    <div className="row">
                        <div className="row shadow col-md-11 ml-3 mb-3 mr-3 bg-white shadow rounded">

                            <div className="breadcrumb"><Link to={"/portal"}>Home</Link> > Scripts</div>
                            <p>
                                <h4>Create a script for an introduction video</h4>

                                <p>Enter some information about yourself and we'll generate a script. Record yourself reading the script, then upload the video. Use the files area of the portal or send the recording as a text message to {process.env.REACT_APP_FILE_UPLOAD_NUMBER}.</p>


                                <form  onSubmit={onSubmit} className="justify-content-center">
                                    <SpinnerComponent loading={loading} position="global" color="#498DFF" message={"Sit tight"}/>
                                    <h4>Update Personal Information</h4>

                                    <div>{errorMessages}</div>

                                    <div className="row form-group">
                                        <div className="col-xl-5">
                                            <label>Name</label>
                                        </div>
                                        <div className="col-xl-7">
                                            <input type="text" className="form-control"  onChange={e => setName(e.target.value)}  disabled={isDisabled} maxLength="32" value={_name}/>
                                        </div>
                                    </div>

                                    <div className="row form-group">
                                        <div className="col-xl-5">
                                            <label>Industry</label>
                                        </div>
                                        <div className="col-xl-7">
                                            <input type="text" className="form-control"  onChange={e => setIndustry(e.target.value)}  disabled={isDisabled} maxLength="32" value={_industry}/>
                                        </div>
                                    </div>

                                    <div className="row form-group">
                                        <div className="col-xl-5">
                                            <label>Years of experience</label>
                                        </div>
                                        <div className="col-xl-7">
                                            <input type="text" className="form-control"  onChange={e => setExperience(e.target.value)}  disabled={isDisabled} maxLength="32" value={_experience}/>
                                        </div>
                                    </div>

                                    <div className="row form-group">
                                        <div className="col-xl-5">
                                            <label>Location</label>
                                        </div>
                                        <div className="col-xl-7">
                                            <input type="text" className="form-control"  onChange={e => setLocation(e.target.value)}  disabled={isDisabled} maxLength="32" value={_location}/>
                                        </div>
                                    </div>


                                    <div className="row text-white d-flex justify-content-center">
                                        <button type="submit" className="btn btn-primary btn-lg" disabled={isDisabled}>Generate</button>
                                    </div>
                                </form>
                            </p>

                            <h5>Script to read</h5>
                            <p>
                                {_script}
                            </p>

                            <div className="row text-white d-flex justify-content-center">
                                <button type="submit" className="btn btn-primary btn-lg" disabled={true}>Save this script</button>
                            </div>


                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className='col-md-12'>
                    <Footer />
                </div>
            </div>
        </div>
    );
}
export default Introduction;

