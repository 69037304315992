import React, {useEffect, useState} from 'react';
import '../App.css';
import {Link, useNavigate, useParams} from 'react-router-dom';
import CookieConsent from "react-cookie-consent";
import Cookies from "universal-cookie";
import axios from "axios";
import DOMPurify from "dompurify";
import {faTriangleExclamation} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { SpinnerComponent } from 'react-element-spinner';

function Code() {
    const navigate = useNavigate();
    const [errors, setErrors] = useState([]);
    const [isDisabled, setDisabled] = useState(false);
    const [code, setCode] = useState('');
    const { email } = useParams();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const cookies = new Cookies();
        const session_id = cookies.get('session_id');

        if (session_id) {
            navigate('/portal');
            return;
        }
    });

    const onSubmit = async (e) => {
        setLoading(true);
        e.preventDefault();
        setDisabled(true);
        const data = {
            email: email,
            code: code
        };

        await axios
            .put(`${process.env.REACT_APP_API_DOMAIN}/api/reset/`, data)
            .then((res) => {
                navigate('/confirmation/');
            })
            .catch((error) => {
                setDisabled(false);

                if (error.response && error.response && error.response.data) {
                    setErrors(error.response.data.notifications);
                } else {
                    setErrors([{'message': 'An error during the login process. Please try again', 'link': ''}]);
                }
            });
        setLoading(false);
    }

    //TODO: This can eventually be its own file, expand and reuse for account edits
    function Message(props) {
        return <div className="alert alert-danger"><FontAwesomeIcon icon={faTriangleExclamation} className='menuIcon'/>{DOMPurify.sanitize(props.message)}</div>;
    }

    const errorMessages = errors.map((error, k) => <Message message={error} link={error.link} key={k}/>);

    return (
        <section id="cover" className="min-vh-100">
            <div id="cover-caption">
                <div className="container">
                    <div className="row text-white">
                        <div className="col-xl-5 col-lg-6 col-md-8 col-sm-10 mx-auto text-center form p-4">
                            <img src="/img/agencynext.png" className="agencyLogo" alt="Agency Next Company Logo"/>
                            <div>{errorMessages}</div>
                            <div className="px-2">
                                <form onSubmit={onSubmit} className="justify-content-center">
                                    <SpinnerComponent loading={loading} position="global" color="#498DFF" message={"One moment"}/>
                                    <div className="form-group">
                                        Please enter the code that was emailed to you. After entering the code, you will be sent a new password.<br/>
                                        If you do not receive a code, please confirm the email address you entered and check your spam folders.
                                    </div>

                                    <div className="form-group">
                                        <label className="sr-only">Code</label>
                                        <input type="text" className="form-control" placeholder="Code" onChange={e => setCode(e.target.value)} disabled={isDisabled}/>
                                    </div>

                                    <button type="submit" className="btn btn-primary btn-lg" disabled={isDisabled}>Submit Code</button>
                                </form>

                                <Link to={`/login`} className="text-white">Login</Link><br/>
                                <Link to={`/register`} className="text-white">Register</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <CookieConsent>This website uses cookies to enhance the user experience.</CookieConsent>
        </section>
    );
}
export default Code;

