import React, {useEffect, useState} from 'react';
import '../App.css';
import Footer from './Footer';
import PageHeader from './PageHeader';
import Menu2 from './Menu2';
import {Link} from 'react-router-dom';
import Cookies from "universal-cookie";
import axios from "axios";
import RssPost from "./shared/RssPost";
import { SpinnerComponent } from 'react-element-spinner';
import Alert from "./todos/Alert";
import Notification from "./todos/Notification";

function Home() {
    const [posts, setPosts] = useState([]);
    const [todos, setTodos] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {

        const cookies = new Cookies();
        const session_id = cookies.get('session_id');

        const fetchData = async () => {
            setLoading(true);
            const postData = await axios
                .get(`${process.env.REACT_APP_API_DOMAIN}/api/news?count=5`, { withCredentials: true });
            setPosts(postData.data);

            const todoData = await axios
                .get(`${process.env.REACT_APP_API_DOMAIN}/api/todo`, {
                    withCredentials: true,
                    headers: {
                        Authorization: session_id
                    }
                });
            setTodos(todoData.data.notifications);
            setLoading(false);
        };
        void fetchData();


    }, []);



    const todoList =
        todos.map((todo, k) => <span>
            {(() => {
                if (todo.type === 'danger'){
                    return <Alert data={todo} />
                } else {
                    return <Notification data={todo} />
                }
            })()}

            </span>);

    const feed =
        posts.map((post, k) => <RssPost data={post} key={k} />);

    return (
        <div className='container-fluid'>
            <div className="row">
                <div className='col-md-12'>
                    <SpinnerComponent loading={loading} position="global" color="#498DFF" message={"Sit tight"}/>
                    <PageHeader />
                </div>
            </div>
            <div className="row">
                <div className='col-md-2'>
                    <Menu2 />
                </div>
                <div className='col-md-10 pageContent'>
                    <div className="row">

                        <div className="col-md-5 ml-3 mb-3 mr-3 bg-white shadow rounded">
                            <strong>Todo List</strong>
                            {todoList}
                        </div>

                        <div className="col-md-5 shadow ml-3 mb-3 mr-3 bg-white rounded panel">
                            <strong>Latest News</strong>
                            <ul>
                                {feed}
                            </ul>
                            <Link to={'/news-feed/'}>See More</Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className='col-md-12'>
                    <Footer />
                </div>
            </div>
        </div>
    );
}
export default Home;

