import React, {useEffect, useState} from 'react';
import '../App.css';
import Footer from './Footer';
import PageHeader from './PageHeader';
import Menu2 from './Menu2';
import axios from "axios";
import {Link} from 'react-router-dom';
import Cookies from "universal-cookie";
import 'react-data-grid/lib/styles.css';
import { SpinnerComponent } from 'react-element-spinner';

function NewsFeed() {
    const [posts, setPosts] = useState([]);
    const [loading, setLoading] = useState(false);
    const [offset, setOffset] = useState(0);

    useEffect(() => {
        const cookies = new Cookies();
        const session_id = cookies.get('session_id');

        const fetchData = async () => {
            setLoading(true);
            const postData = await axios
                .get(`${process.env.REACT_APP_API_DOMAIN}/api/news?count=25&offset=` + offset, {
                    withCredentials: true,
                    headers: {
                        Authorization: session_id
                    }
                });
            setPosts(postData.data);
            setLoading(false);
        };
        void fetchData();
        window.scrollTo(0, 0)
    }, [offset]);


    const newsPosts = posts.map((source, k) => <tr>
        <td><span className={'text-nowrap'}>{source._published_date}</span></td>
        <td><Link className={'text-nowrap'} to={`/news-sources/${source._site}`}>{source._sitename}</Link></td>
        <td><Link target={'_blank'} to={source._url}>{source._title}</Link></td></tr>);

    return (
        <div className='container-fluid'>
            <div className="row">
                <div className='col-md-12'>
                    <PageHeader />
                </div>
            </div>
            <div className="row">
                <div className='col-md-2'>
                    <Menu2 />
                </div>
                <div className='col-md-10 pageContent'>
                    <div className="row">
                        <div className="row shadow col-md-11 ml-3 mb-3 mr-2 bg-white shadow rounded">
                            <div className="breadcrumb"><Link to={"/portal"}>Home</Link> > Industry News</div>
                            <div className='col-md-10 pageContent'>
                                <div className="row">
                                    <SpinnerComponent loading={loading} position="global" color="#498DFF" message={"Sit tight"}/>
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th scope="col">
                                                    Posted
                                                </th>
                                                <th scope="col">
                                                    Source
                                                </th>
                                                <th scope="col">
                                                    Headline
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {newsPosts}
                                        </tbody>
                                        <tfoot>
                                            <tr>
                                                <td colspan="3">
                                                    <button type="button" className={offset === 0 ? "btn mr-2 btn-primary" : "btn mr-2 btn-outline-primary"} onClick={()=> setOffset(0)}>1</button>
                                                    <button type="button" className={offset === 25 ? "btn mr-2 btn-primary" : "btn mr-2 btn-outline-primary"} onClick={()=> setOffset(25)}>2</button>
                                                    <button type="button" className={offset === 50 ? "btn mr-2 btn-primary" : "btn mr-2 btn-outline-primary"} onClick={()=> setOffset(50)}>3</button>
                                                    <button type="button" className={offset === 75 ? "btn mr-2 btn-primary" : "btn mr-2 btn-outline-primary"} onClick={()=> setOffset(75)}>4</button>
                                                    <button type="button" className={offset === 100 ? "btn mr-2 btn-primary" : "btn mr-2 btn-outline-primary"} onClick={()=> setOffset(100)}>5</button>
                                                    <button type="button" className={offset === 125 ? "btn mr-2 btn-primary" : "btn mr-2 btn-outline-primary"} onClick={()=> setOffset(125)}>6</button>
                                                    <button type="button" className={offset === 150 ? "btn mr-2 btn-primary" : "btn mr-2 btn-outline-primary"} onClick={()=> setOffset(150)}>7</button>
                                                    <button type="button" className={offset === 175 ? "btn mr-2 btn-primary" : "btn mr-2 btn-outline-primary"} onClick={()=> setOffset(175)}>8</button>
                                                    <button type="button" className={offset === 200 ? "btn mr-2 btn-primary" : "btn mr-2 btn-outline-primary"} onClick={()=> setOffset(200)}>9</button>
                                                    <button type="button" className={offset === 225 ? "btn mr-2 btn-primary" : "btn mr-2 btn-outline-primary"} onClick={()=> setOffset(225)}>10</button>
                                                    <button type="button" className={offset === 250 ? "btn mr-2 btn-primary" : "btn mr-2 btn-outline-primary"} onClick={()=> setOffset(250)}>11</button>
                                                    <button type="button" className={offset === 275 ? "btn mr-2 btn-primary" : "btn mr-2 btn-outline-primary"} onClick={()=> setOffset(275)}>12</button>
                                                    <button type="button" className={offset === 300 ? "btn mr-2 btn-primary" : "btn mr-2 btn-outline-primary"} onClick={()=> setOffset(300)}>13</button>
                                                    <button type="button" className={offset === 325 ? "btn mr-2 btn-primary" : "btn mr-2 btn-outline-primary"} onClick={()=> setOffset(325)}>14</button>
                                                    <button type="button" className={offset === 350 ? "btn mr-2 btn-primary" : "btn mr-2 btn-outline-primary"} onClick={()=> setOffset(350)}>15</button>
                                                    <button type="button" className={offset === 375 ? "btn mr-2 btn-primary" : "btn mr-2 btn-outline-primary"} onClick={()=> setOffset(375)}>16</button>
                                                    <button type="button" className={offset === 400 ? "btn mr-2 btn-primary" : "btn mr-2 btn-outline-primary"} onClick={()=> setOffset(400)}>17</button>
                                                    <button type="button" className={offset === 425 ? "btn mr-2 btn-primary" : "btn mr-2 btn-outline-primary"} onClick={()=> setOffset(425)}>18</button>
                                                    <button type="button" className={offset === 450 ? "btn mr-2 btn-primary" : "btn mr-2 btn-outline-primary"} onClick={()=> setOffset(450)}>19</button>
                                                    <button type="button" className={offset === 475 ? "btn mr-2 btn-primary" : "btn mr-2 btn-outline-primary"} onClick={()=> setOffset(475)}>20</button>
                                                </td>
                                            </tr>
                                        </tfoot>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className='col-md-12'>
                    <Footer />
                </div>
            </div>
        </div>
    );
}
export default NewsFeed;


