import React, {useEffect, useState} from 'react';
import '../App.css';
import Footer from './Footer';
import PageHeader from './PageHeader';
import Menu2 from './Menu2';
import {Link} from "react-router-dom";
import Cookies from "universal-cookie";
import axios from "axios";
import { SpinnerComponent } from 'react-element-spinner';

function Account() {

    const [physicalAddress, setPhysicalAddress] = useState({
        _line1: '',
        _line2: '',
        _city: '',
        _state: '',
        _zip: '',
        _zip_suffix: ''
    });
    const [billingAddress, setBillingAddress] = useState({
        _line1: '',
        _line2: '',
        _city: '',
        _state: '',
        _zip: '',
        _zip_suffix: ''
    });
    const [profile, setProfile] = useState({});
    const [loading, setLoading] = useState(false);

    useEffect(() => {

        const cookies = new Cookies();
        const session_id = cookies.get('session_id');

        const fetchData = async () => {
            setLoading(true);
            const addressData = await axios
                .get(`${process.env.REACT_APP_API_DOMAIN}/api/address/PHYSICAL`, {
                    withCredentials: true,
                    headers: {
                        Authorization: session_id
                    }
                });
            if(addressData.data) {
                setPhysicalAddress(addressData.data);
            }


            const billingAddressData = await axios
                .get(`${process.env.REACT_APP_API_DOMAIN}/api/address/BILLING`, {
                    withCredentials: true,
                    headers: {
                        Authorization: session_id
                    }
                });
            if(addressData.data) {
                setBillingAddress(billingAddressData.data);
            }

            const profileData = await axios
                .get(`${process.env.REACT_APP_API_DOMAIN}/api/user`, {
                    withCredentials: true,
                    headers: {
                        Authorization: session_id
                    }
                });

            const cleaned = ('' + profileData.data._phone).replace(/\D/g, '');
            const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
            if (match) {
                profileData.data._formatted_phone =  '(' + match[1] + ') ' + match[2] + '-' + match[3];
            }

            const cleanedCell = ('' + profileData.data._cell).replace(/\D/g, '');
            const matchCell = cleanedCell.match(/^(\d{3})(\d{3})(\d{4})$/);
            if (matchCell) {
                profileData.data._formatted_cell =  '(' + matchCell[1] + ') ' + matchCell[2] + '-' + matchCell[3];
            }

            setProfile(profileData.data);
            setLoading(false);
        };
        void fetchData();

    }, []);



    return (
        <div className='container-fluid'>
            <div className="row">
                <div className='col-md-12'>
                    <PageHeader />
                </div>
            </div>
            <div className="row">
                <div className='col-md-2'>
                    <Menu2 />
                </div>
                <div className='col-md-10 pageContent'>
                    <div className="row">
                        <div className="row shadow col-md-11 ml-3 mb-3 mr-3 bg-white shadow rounded">

                            <div className="breadcrumb"><Link to={"/portal"}>Home</Link> > Account</div>
                            <div className='col-md-10 pageContent'>
                                <div className="row">
                                    <Link to={"/account/password"}>Change Password</Link>
                                </div>

                                <div className="row">
                                    <SpinnerComponent loading={loading} position="global" color="#498DFF" message={"Sit tight"}/>
                                    <h5>Personal Information [<Link to={"/account/profileedit"}>Edit</Link>]</h5>
                                </div>
                                <div className="row">
                                    <dt className="col-sm-3">Name</dt><dd className="col-sm-9">{profile._first} {profile._last}</dd>
                                    <dt className="col-sm-3">Office Phone</dt><dd className="col-sm-9">{profile._formatted_phone}&nbsp;&nbsp;{profile._phone_extension ? 'x' + profile._phone_extension : '' }</dd>
                                    <dt className="col-sm-3">Mobile Phone</dt>
                                    <dd className="col-sm-9">{profile._formatted_cell}</dd>
                                    <dt className="col-sm-3">Email</dt>
                                    <dd className="col-sm-9">{profile._email}</dd>
                                    <dt className="col-sm-3">Timezone</dt>
                                    <dd className="col-sm-9">{profile._timezone}</dd>
                                    <dt className="col-sm-3">Email Verified?</dt>
                                    <dd className="col-sm-9">{(() => {
                                        return (profile._email_verified ? 'Yes' : 'No')
                                    })()}</dd>
                                </div>

                                <div className="row">
                                    <h5>Physical Address [<Link to={"/account/addressedit/PHYSICAL"}>Edit</Link>]</h5>
                                </div>
                                <div className="row">
                                    {physicalAddress._line1}<br />
                                    {physicalAddress._line2 ? physicalAddress._line2 : '' }
                                    {physicalAddress._line2 ? <br/> : '' }
                                    {physicalAddress._city}, {physicalAddress._state} {physicalAddress._zip}
                                    {physicalAddress._zip_suffix ? '-' + physicalAddress._zip_suffix : '' }
                                </div>

                                <div className="row">
                                    <h5>Billing Address</h5><br/>
                                </div>
                                <div className="row">
                                    {billingAddress._line1}<br />
                                    {billingAddress._line2 ? billingAddress._line2 : '' }
                                    {billingAddress._line2 ? <br/> : '' }
                                    {billingAddress._city}, {billingAddress._state} {billingAddress._zip}
                                    {billingAddress._zip_suffix ? '-' + billingAddress._zip_suffix : '' }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className='col-md-12'>
                    <Footer />
                </div>
            </div>
        </div>
    );
}
export default Account;


