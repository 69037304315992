import {Link} from "react-router-dom";
import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";


const Notification = (props) => {

    const todo = props.data;

    return (
        <div className={`alert alert-${todo.type}`}>
            <FontAwesomeIcon icon={faCircleInfo} className='menuIcon' />
            <Link to={todo.link}>{todo.message}</Link>
        </div>
    );

};


export default Notification;
