import React, {useEffect, useState} from 'react';
import '../App.css';
import Footer from './Footer';
import PageHeader from './PageHeader';
import Menu2 from './Menu2';
import axios from "axios";
import {Link, useNavigate} from 'react-router-dom';
import Cookies from "universal-cookie";
import { SpinnerComponent } from 'react-element-spinner';

function FilesPublished() {
    const [files, setFiles] = useState([]);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const cookies = new Cookies();
        const session_id = cookies.get('session_id');

        const fetchData = async () => {
            setLoading(true);
            const feedData = await axios
                .get(`${process.env.REACT_APP_API_DOMAIN}/api/media?status=PUBLISHED`, {
                    withCredentials: true,
                    headers: {
                        Authorization: session_id
                    }
                });
            setFiles(feedData.data);
            setLoading(false);
        };
        void fetchData();
    }, [navigate]);

    function Source(props) {
        return <tr>
            <td><Link target={'_blank'} to={`/files-download/${props.data._id}`}>{props.data._filename}</Link></td>
            <td>{props.data._size ? (props.data._size / 1048576).toFixed(3) : 0} MB</td>
            <td>{props.data._created}</td>
            <td>{props.data._updated}</td></tr>
    }

    const filesList =
        files.map((source, k) => <Source data={source} key={k} />);

    return (
        <div className='container-fluid'>
            <div className="row">
                <div className='col-md-12'>
                    <PageHeader />
                </div>
            </div>
            <div className="row">
                <div className='col-md-2'>
                    <Menu2 />
                </div>
                <div className='col-md-10 pageContent'>
                    <div className="row">
                        <div className="row shadow col-md-11 ml-3 mb-3 mr-3 bg-white shadow rounded">
                            <div className="breadcrumb"><Link to={"/portal"}>Home</Link> > Published Media</div>
                            <p>
                                <h4>Published Media</h4><SpinnerComponent loading={loading} position="global" color="#498DFF" message={"Sit tight"}/>
                                <p>These files have been approved for use. They are provided here for your convenience and reference.</p>
                                <p>Right click the file name and choose &quot;Save as&quot; to save the file to your computer.</p>

                                <p><table className="table">
                                    <tr>

                                        <th scope="col">
                                            File Name
                                        </th>
                                        <th>
                                           Size
                                        </th>
                                        <th scope="col">
                                            Upload Date
                                        </th>
                                        <th scope="col">
                                            Last Changed
                                        </th>
                                    </tr>
                                    {filesList}

                                </table></p>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className='col-md-12'>
                    <Footer />
                </div>
            </div>
        </div>
    );
}
export default FilesPublished;

