import React, {useEffect, useState} from 'react';
import '../../App.css';
import Footer from '../Footer';
import PageHeader from '../PageHeader';
import Menu2 from '../Menu2';
import {Link, useNavigate, useParams} from "react-router-dom";
import axios from "axios";
import Cookies from "universal-cookie";
import { SpinnerComponent } from 'react-element-spinner';


function Message() {
    const [message, setMessage] = useState([]);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const { id } = useParams();

    useEffect(() => {
        const cookies = new Cookies();
        const session_id = cookies.get('session_id');

        const fetchData = async () => {
            setLoading(true);
            const messageData = await axios
                .get(`${process.env.REACT_APP_API_DOMAIN}/api/message/${id}`, {
                    withCredentials: true,
                    headers: {
                        Authorization: session_id
                    }
                });
            await axios
                .post(`${process.env.REACT_APP_API_DOMAIN}/api/message/${id}`,{
                    'viewed' : new Date()
                },
                    {
                    withCredentials: true,
                    headers: {
                        Authorization: session_id
                    }
                });
            setMessage(messageData.data);
            setLoading(false);
        };
        void fetchData();

    }, [id, navigate]);


    return (
        <div className='container-fluid'>
            <div className="row">
                <div className='col-md-12'>
                    <PageHeader />
                </div>
            </div>
            <div className="row">
                <div className='col-md-2'>
                    <Menu2 />
                </div>
                <div className='col-md-10 pageContent'>
                    <div className="row">
                        <div className="row shadow col-md-11 ml-3 mb-3 mr-3 bg-white shadow rounded">
                            <SpinnerComponent loading={loading} position="global" color="#498DFF" message={"Sit tight"}/>
                            <div className="breadcrumb"><Link to={"/portal"}>Home</Link> > <Link to={"/messages"}>Messages</Link> > Message</div>
                            <p>
                                <table className="table">
                                    <tr>
                                        <dt >
                                            Sender
                                        </dt>
                                        <dd >
                                            {message._sender}
                                        </dd>
                                    </tr>
                                    <tr>
                                        <dt >
                                            Subject
                                        </dt>
                                        <dd >
                                            {message._subject}
                                        </dd>
                                    </tr>
                                    <tr>
                                        <dt >
                                            Date
                                        </dt>
                                        <dd >
                                            {message._created}
                                        </dd>
                                    </tr>
                                    <tr>
                                        <td >
                                            {message._message}
                                        </td>
                                    </tr>
                                </table>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className='col-md-12'>
                    <Footer />
                </div>
            </div>
        </div>
    );
}
export default Message;

