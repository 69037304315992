import React from 'react';
import {Link} from 'react-router-dom';
import '../App.css';
import Footer from './Footer';
import PageHeader from './PageHeader';
import Menu2 from './Menu2';

function Documents() {

    return (
        <div className='container-fluid bigboi'>
            <div className="row">
                <div className='col-md-12'>
                    <PageHeader />
                </div>
            </div>
            <div className="row">
                <div className='col-md-2'>
                    <Menu2 />
                </div>
                <div className='col-md-10'>
                    <div className="breadcrumb"><Link to={"/portal"}>Home</Link> > Documentation</div>
                    <h5>Documents</h5>
                    <ul>
                        <li>Coming</li>
                        <li>Soon</li>
                    </ul>
                </div>
            </div>
            <div className="row">
                <div className='col-md-12'>
                    <Footer />
                </div>
            </div>
        </div>
    );
}
export default Documents;

