import React, {useEffect, useState} from 'react';
import '../../App.css';
import Footer from '../Footer';
import PageHeader from '../PageHeader';
import Menu2 from '../Menu2';
import {Link, useNavigate} from "react-router-dom";
import axios from "axios";
import Cookies from "universal-cookie";
import { SpinnerComponent } from 'react-element-spinner';


function Messages() {
    const [messages, setMessages] = useState([]);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const cookies = new Cookies();
        const session_id = cookies.get('session_id');

        const fetchData = async () => {
            setLoading(true);
            const messageData = await axios
                .get(`${process.env.REACT_APP_API_DOMAIN}/api/message`, {
                    withCredentials: true,
                    headers: {
                        Authorization: session_id
                    }
                });
            setMessages(messageData.data);
            setLoading(false);
        };
        void fetchData();

    }, [navigate]);

    const messageRows =
        messages.map((message, k) => <tr className={message._viewed ? '' : 'unreadmessage'}>
            <td>{message._sender}</td>
            <td><Link to={`/message/${message._id}`}>{message._subject}</Link></td>
            <td>{message._created}</td></tr>);


    return (
        <div className='container-fluid'>
            <div className="row">
                <div className='col-md-12'>
                    <PageHeader />
                </div>
            </div>
            <div className="row">
                <div className='col-md-2'>
                    <Menu2 />
                </div>
                <div className='col-md-10 pageContent'>
                    <div className="row">
                        <div className="row shadow col-md-11 ml-3 mb-3 mr-3 bg-white shadow rounded">
                            <SpinnerComponent loading={loading} position="global" color="#498DFF" message={"Sit tight"}/>
                            <div className="breadcrumb"><Link to={"/portal"}>Home</Link> > Messages</div>
                            <p>
                                <h4>Messages</h4>
                                <table className="table">
                                    <tr>
                                        <th scope="col">
                                            Sender
                                        </th>
                                        <th scope="col">
                                            Subject
                                        </th>
                                        <th scope="col">
                                            Date
                                        </th>
                                    </tr>
                                    {messageRows}
                                </table>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className='col-md-12'>
                    <Footer />
                </div>
            </div>
        </div>
    );
}
export default Messages;

