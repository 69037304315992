import React, { useState, useEffect } from 'react';
import {Link, useNavigate, useParams} from 'react-router-dom';
import '../App.css';
import Footer from './Footer';
import PageHeader from './PageHeader';
import Menu2 from './Menu2';
import axios from 'axios';
import DOMPurify from "dompurify";

function IframePage() {
    const [page, setPage] = useState({});
    const { id } = useParams();
    const navigate = useNavigate();

    useEffect(() => {

        axios
            .get(`${process.env.REACT_APP_API_DOMAIN}/api/page/${id}`, { withCredentials: true })
            .then((res) => {
                setPage(res.data);
            })
            .catch((err) => {
                console.log('Error from API');
            });
    }, [id, navigate]);

    return (
        <div className='container-fluid bigboi'>
            <div className="row">
                <div className='col-md-12'>
                    <PageHeader />
                </div>
            </div>
            <div className="row bigboi">
                <div className='col-md-2'>
                    <Menu2 />
                </div>
                <div className='col-md-10 iframePageContent'>
                    <div className="breadcrumb"><Link to={"/portal"}>Home</Link> > {DOMPurify.sanitize(page.title)}</div>
                    <iframe src={page.url} title={page.title} className="iframe"/>
                </div>
            </div>
            <div className="row">
                <div className='col-md-12'>
                    <Footer />
                </div>
            </div>
        </div>
    );
}
export default IframePage;

