import React from 'react';
import {Link} from "react-router-dom";

const Footer = (props) => {

    return (
        <div className="row footer">
            <div className='col-md-3'>
                &copy; 2024 <Link className="text-light" to="https://www.agencynext.com/" target="_blank">Agency Next</Link>
            </div>
            <div className='col-md-6'>
            </div>
            <div className='col-md-3'>
                <Link className="text-light" to="https://www.agencynext.com/terms-of-service" target="_blank">Terms of Service</Link> | <Link className="text-light" to="https://www.agencynext.com/privacy-policy" target="_blank">Privacy Policy</Link>
            </div>

        </div>

    );

}
export default Footer;
