import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Login from './components/Login';
import Home from './components/Home';
import Logout from './components/Logout';
import Register from './components/Register';
import BrandingPage from './components/BrandingPage';
import NewsFeed from './components/NewsFeed';
import NewsSources from './components/NewsSources';
import Account from './components/Account';
import Invoices from './components/Invoices';
import Invoice from './components/Invoice';
import FilesPublished from './components/FilesPublished';
import FilesUpload from './components/FilesUpload';
import FilesSubmitted from './components/FilesSubmitted';
import FilesDrafts from './components/FilesDrafts';
import FilesDownload from './components/FilesDownload';
import IframePage from './components/IframePage';
import NewsDetail from './components/NewsDetail';
import Address from './components/account/Address';
import AddressEdit from './components/account/AddressEdit';
import Profile from './components/account/Profile';
import ProfileEdit from './components/account/ProfileEdit';
import EmailEdit from './components/account/EmailEdit';
import Password from "./components/account/Password";
import Confirmation from "./components/Confirmation";
import Code from "./components/Code";
import Message from "./components/account/Message";
import Messages from "./components/account/Messages";
import Script from "./components/Script";
import Scripts from "./components/Scripts";
import Documents from "./components/Documents";
import Impersonate from "./components/Impersonate";
import ScriptMenu from "./components/scripts/Menu";
import PMI from "./components/scripts/PMI";
import Introduction from "./components/scripts/Introduction";

import Forgot from "./components/Forgot";
import './App.css';

const App = () => {
  return (
      <Router>
          <Routes>
            <Route path='/' element={<Login />} />
            <Route path='/login' element={<Login />} />
            <Route path='/portal' element={<Home />} />
            <Route path='/logout' element={<Logout />} />
            <Route path='/website/brandingpage' element={<BrandingPage />} />
            <Route path='/register' element={<Register />} />
            <Route path='/forgot' element={<Forgot />} />
            <Route path='/news-feed' element={<NewsFeed />} />
            <Route path='/news-sources' element={<NewsSources />} />
            <Route path='/news-sources/:id' element={<NewsDetail />} />
            <Route path='/account/address' element={<Address />} />
            <Route path='/account/addressedit/:type' element={<AddressEdit />} />
            <Route path='/account/email' element={<EmailEdit />} />
            <Route path='/account/password' element={<Password />} />
            <Route path='/account/profile' element={<Profile />} />
            <Route path='/account/profileedit' element={<ProfileEdit />} />
            <Route path='/account' element={<Account />} />
            <Route path='/invoices' element={<Invoices />} />
            <Route path='/invoice/:id' element={<Invoice />} />
            <Route path='/files-published' element={<FilesPublished />} />
            <Route path='/files-upload' element={<FilesUpload />} />
            <Route path='/files-drafts' element={<FilesDrafts />} />
            <Route path='/files-submitted' element={<FilesSubmitted />} />
            <Route path='/files-download/:id' element={<FilesDownload />} />
            <Route path='/page/:id' element={<IframePage />} />
            <Route path='/messages/' element={<Messages />} />
            <Route path='/message/:id' element={<Message />} />
            <Route path='/documents/' element={<Documents />} />
            <Route path='/scripts/' element={<Scripts />} />
            <Route path='/script/:id' element={<Script />} />
            <Route path='/impersonate/:id' element={<Impersonate />} />
            <Route path='/code/:email' element={<Code />} />
            <Route path='/confirmation' element={<Confirmation />} />
            <Route path='/forgot' element={<Forgot />} />
            <Route path='/scripts/menu' element={<ScriptMenu />} />
            <Route path='/scripts/pmi' element={<PMI />} />
            <Route path='/scripts/introduction' element={<Introduction />} />
          </Routes>
      </Router>
  );
};


export default App;

