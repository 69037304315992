import React, {useEffect, useState} from 'react';
import '../App.css';
import {useNavigate} from 'react-router-dom';
import Cookies from 'universal-cookie';
import axios from 'axios';
import DOMPurify from "dompurify";
import { faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { SpinnerComponent } from 'react-element-spinner';

function Register({ history }) {
    const [errors, setErrors] = useState([]);
    const [isDisabled, setDisabled] = useState(false);
    const [user, setUser] = useState({
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        confirmpassword: '',
    });
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const cookies = new Cookies();
        const session_id = cookies.get('session_id');

        if(session_id) {
            navigate('/portal');
            return;
        }
    });

    const onSubmit = async (e) => {
        setLoading(true);
        e.preventDefault();
        setDisabled(true);
        const data = {
            firstName: user.firstName,
            lastName: user.lastName,
            email: user.email,
            password: user.password,
            confirmpassword: user.confirmpassword,
        };

        await axios
            .post(`${process.env.REACT_APP_API_DOMAIN}/api/register/`, data)
            .then((res) => {
                const cookies = new Cookies();
                cookies.set('session_id', res.data.sessionId, { path: '/', domain: process.env.REACT_APP_EXPERIMENT_COOKIE_DOMAIN });
                navigate('/portal/');
            })
            .catch((error) => {
                console.log(JSON.stringify(error.response.data));
                setDisabled(false);

                if(error.response && error.response && error.response.data) {
                    setErrors(error.response.data.messages);
                } else {
                    setErrors([{'message': 'An error occurred creating your account. Please try again','link': ''}]);
                }
            });
        setLoading(false);

    };

    //TODO: This can eventually be its own file, expand and reuse for account edits
    function Message(props) {
        return <div  className="alert alert-danger"><FontAwesomeIcon icon={faTriangleExclamation} className='menuIcon' />{DOMPurify.sanitize(props.message)}</div>;
    }

    const errorMessages =
        errors.map((error, k) => <Message message={error.message} link={error.link} key={k} />);

    const onChange = (e) => {
        setUser({ ...user, [e.target.name]: e.target.value });
    };

    return (
        <section id="cover" className="min-vh-100">
            <div id="cover-caption">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-5 col-lg-6 col-md-8 col-sm-10 mx-auto text-center form p-4 bg-white">
                            <img src="img/agencynext.png" className="agencyLogo"  alt="Agency Next Company Logo"/>
                            <div>{errorMessages}</div>
                            <div className="px-2">
                                <form  onSubmit={onSubmit} className="justify-content-center">
                                    <SpinnerComponent loading={loading} position="global" color="#498DFF" message={"Sit tight"}/>
                                    <div className="row form-group">
                                        <div className="col-xl-4 text-right">
                                            <label>First Name</label>
                                        </div>
                                        <div className="col-xl-8">
                                            <input type="text" name="firstName" className="form-control" onChange={onChange}  disabled={isDisabled}/>
                                        </div>
                                    </div>

                                    <div className="row form-group">
                                        <div className="col-xl-4 text-right">
                                            <label>Last Name</label>
                                        </div>
                                        <div className="col-xl-8">
                                            <input type="text" name="lastName" className="form-control"  onChange={onChange}  disabled={isDisabled}/>
                                        </div>
                                    </div>

                                    <div className="row form-group">
                                        <div className="col-xl-4 text-right">
                                            <label>Email</label>
                                        </div>
                                        <div className="col-xl-8">
                                            <input type="text" name="email" className="form-control"  onChange={onChange} disabled={isDisabled}/>
                                        </div>
                                    </div>

                                    <div className="row form-group">
                                        <div className="col-xl-4 text-right">
                                            <label>Password</label>
                                        </div>
                                        <div className="col-xl-8">
                                            <input type="password" name="password" className="form-control"  onChange={onChange} disabled={isDisabled}/>
                                        </div>
                                    </div>

                                    <div className="row form-group">
                                        <div className="col-xl-4 text-right">
                                            <label>Confirm Password</label>
                                        </div>
                                        <div className="col-xl-8">
                                            <input type="password" name="confirmpassword" className="form-control"  onChange={onChange} disabled={isDisabled}/>
                                        </div>
                                    </div>
                                    <div className="row text-white d-flex justify-content-center">
                                        <button type="submit" className="btn btn-primary btn-lg" disabled={isDisabled}>Register</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    </section>
)
    ;
}

export default Register;

