import React, {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import '../../App.css';
import Footer from '../Footer';
import PageHeader from '../PageHeader';
import Menu2 from '../Menu2';
import {Link} from "react-router-dom";
import axios from "axios";
import Cookies from "universal-cookie";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTriangleExclamation} from "@fortawesome/free-solid-svg-icons";
import DOMPurify from "dompurify";
import { SpinnerComponent } from 'react-element-spinner';

function Password() {

    const [errors, setErrors] = useState([]);
    const [isDisabled, setDisabled] = useState(false);
    const [password, setPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [loading, setLoading] = useState(false);


    const navigate = useNavigate();

    const onSubmit = async (e) => {
        setLoading(true);
        e.preventDefault();
        setDisabled(true);

        const cookies = new Cookies();
        const session_id = cookies.get('session_id');

        const submission = {
            'password' : password,
            'newPassword' : newPassword,
            'confirmPassword' : confirmPassword
        }

        await axios
            .post(`${process.env.REACT_APP_API_DOMAIN}/api/changepw/`, submission,{
                withCredentials: true,
                    headers: {
                    Authorization: session_id
               }
            })
            .then((res) => {
                navigate('/account/');
            })
            .catch((error) => {
                console.log(JSON.stringify(error.response.data));
                setDisabled(false);

                if(error.response && error.response && error.response.data) {
                    setErrors(error.response.data.notifications);
                } else {
                    setErrors([{'message': 'An error occurred changing your password. Please try again','link': ''}]);
                }
            });
        setLoading(false);
    };

    function Message(props) {
        return <div  className="alert alert-danger"><FontAwesomeIcon icon={faTriangleExclamation} className='menuIcon' />{DOMPurify.sanitize(props.message)}</div>;
    }

    const errorMessages =
        errors.map((error, k) => <Message message={error} key={k} />);

    return (
        <div className='container-fluid'>
            <div className="row">
                <div className='col-md-12'>
                    <PageHeader />
                </div>
            </div>
            <div className="row">
                <div className='col-md-2'>
                    <Menu2 />
                </div>
                <div className='col-md-10 pageContent'>
                    <div className="row">
                        <div className="row shadow col-md-11 ml-3 mb-3 mr-3 bg-white shadow rounded">
                            <div className="breadcrumb"><Link to={"/portal"}>Home</Link> > <Link to={"/account"}>Account</Link> > Update Password</div>
                            <p>
                                <div>{errorMessages}</div>
                                <div className="px-2">
                                    <form  onSubmit={onSubmit} className="justify-content-center">
                                        <SpinnerComponent loading={loading} position="global" color="#498DFF" message={"Sit tight"}/>
                                        <h4>Change Password</h4>
                                        <div className="row form-group">
                                            <div className="col-xl-4 text-right">
                                                <label>Current Password</label>
                                            </div>
                                            <div className="col-xl-8">
                                                <input type="password" className="form-control"  onChange={e => setPassword(e.target.value)} disabled={isDisabled}/>
                                            </div>
                                        </div>

                                        <div className="row form-group">
                                            <div className="col-xl-4 text-right">
                                                <label>New Password</label>
                                            </div>
                                            <div className="col-xl-8">
                                                <input type="password" className="form-control"  onChange={e => setNewPassword(e.target.value)} disabled={isDisabled}/>
                                            </div>
                                        </div>

                                        <div className="row form-group">
                                            <div className="col-xl-4 text-right">
                                                <label>Confirm Password</label>
                                            </div>
                                            <div className="col-xl-8">
                                                <input type="password" className="form-control"  onChange={e => setConfirmPassword(e.target.value)} disabled={isDisabled}/>
                                            </div>
                                        </div>
                                        <div className="row text-white d-flex justify-content-center">
                                            <button type="submit" className="btn btn-primary btn-lg" disabled={isDisabled}>Change</button>
                                        </div>
                                    </form>
                                </div>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className='col-md-12'>
                    <Footer />
                </div>
            </div>
        </div>
    );
}
export default Password;


