import React, {useEffect, useState} from 'react';
import '../App.css';
import Footer from './Footer';
import PageHeader from './PageHeader';
import Menu2 from './Menu2';
import axios from "axios";
import DOMPurify from "dompurify";
import {Link, useNavigate} from 'react-router-dom';
import Cookies from "universal-cookie";
import { SpinnerComponent } from 'react-element-spinner';

function NewsSources() {
    const [sources, setSources] = useState([]);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const cookies = new Cookies();
        const session_id = cookies.get('session_id');

        const fetchData = async () => {
            setLoading(true);
            const feedData = await axios
                .get(`${process.env.REACT_APP_API_DOMAIN}/api/feed`, {
                    withCredentials: true,
                    headers: {
                        Authorization: session_id
                    }
                });
            setSources(feedData.data);
            setLoading(false);
        };
        void fetchData();
    }, [navigate]);

    function Source(props) {

        return <li className="rssPost">
            <Link to={DOMPurify.sanitize(props.data._siteurl)} target="_blank">{DOMPurify.sanitize(props.data._title)}</Link> [<Link to={`/news-sources/${props.data._id}`}>Stories</Link>]<br />
            <small>{DOMPurify.sanitize(props.data._siteurl)}</small>
        </li>
    }

    const newsSources =
        sources.map((source, k) => <Source data={source} key={k} />);

    return (
        <div className='container-fluid'>
            <div className="row">
                <div className='col-md-12'>
                    <PageHeader />
                </div>
            </div>
            <div className="row">
                <div className='col-md-2'>
                    <Menu2 />
                </div>
                <div className='col-md-10 pageContent'>
                    <div className="row">
                        <div className="row shadow col-md-11 ml-3 mb-3 mr-3 bg-white shadow rounded">
                            <div className="breadcrumb"><Link to={"/portal"}>Home</Link> > News Sources</div>
                            <p>
                                <h4>News Sources</h4><SpinnerComponent loading={loading} position="global" color="#498DFF" message={"Sit tight"}/>

                                <p><ul>{newsSources}</ul></p>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className='col-md-12'>
                    <Footer />
                </div>
            </div>
        </div>
    );
}
export default NewsSources;

