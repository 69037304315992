import React from 'react';
import '../../App.css';
import Footer from '../Footer';
import PageHeader from '../PageHeader';
import Menu2 from '../Menu2';
import {Link} from "react-router-dom";






function PMI() {



    return (
        <div className='container-fluid'>
            <div className="row">
                <div className='col-md-12'>
                    <PageHeader />
                </div>
            </div>
            <div className="row">
                <div className='col-md-2'>
                    <Menu2 />
                </div>
                <div className='col-md-10 pageContent'>
                    <div className="row">
                        <div className="row shadow col-md-11 ml-3 mb-3 mr-3 bg-white shadow rounded">

                            <div className="breadcrumb"><Link to={"/portal"}>Home</Link> > Scripts</div>
                            <p>
                                <h4>Scripts</h4>
                                Script instructions, what to do with them, etc. Give me some better copy to put here.

                                <ul>
                                    <li><Link to="/scripts/introduction">Introduction Video</Link></li>
                                    <li><Link to="/scripts/pmi">Why private mortgage insurance is great</Link></li>
                                </ul>


                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className='col-md-12'>
                    <Footer />
                </div>
            </div>
        </div>
    );
}
export default PMI;

