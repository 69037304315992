import {Link} from "react-router-dom";
import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTriangleExclamation} from "@fortawesome/free-solid-svg-icons";

const Alert = (props) => {

    const todo = props.data;

    return (
        <div className={"alert alert-danger"}>
            <FontAwesomeIcon icon={faTriangleExclamation} className='menuIcon' />
            <Link to={todo.link}>{todo.message}</Link
        ></div>
    );

};


export default Alert;
