import React, {useEffect, useState} from 'react';
import '../../App.css';
import Footer from '../Footer';
import PageHeader from '../PageHeader';
import Menu2 from '../Menu2';
import {Link, useNavigate, useParams} from 'react-router-dom';
import axios from "axios";
import Cookies from "universal-cookie";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTriangleExclamation} from "@fortawesome/free-solid-svg-icons";
import DOMPurify from "dompurify";
import { SpinnerComponent } from 'react-element-spinner';

function AddressEdit() {

    const [errors, setErrors] = useState([]);
    const [isDisabled, setDisabled] = useState(false);
    const [_line1, setLine1] = useState('');
    const [_line2, setLine2] = useState('');
    const [_city, setCity] = useState('');
    const [_state, setState] = useState('');
    const [_zip, setZip] = useState('');
    const [_zip_suffix, setZipSuffix] = useState('');
    const [loading, setLoading] = useState(false);


    const navigate = useNavigate();
    const { type } = useParams();
    const cookies = new Cookies();
    const session_id = cookies.get('session_id');


    useEffect(() => {
        const cookies = new Cookies();
        const session_id = cookies.get('session_id');

        const fetchData = async () => {
            setLoading(true);
            const addressData = await axios
                .get(`${process.env.REACT_APP_API_DOMAIN}/api/address/${type}`, {
                    withCredentials: true,
                    headers: {
                        Authorization: session_id
                    }
                });

            setLine1(addressData.data._line1);
            setLine2(addressData.data._line2);
            setCity(addressData.data._city);
            setState(addressData.data._state);
            setZip(addressData.data._zip);
            setZipSuffix(addressData.data._zip_suffix);
            setLoading(false);
        };
        void fetchData();

    }, [type]);

    const onSubmit = async (e) => {
        setLoading(true);
        e.preventDefault();
        setDisabled(true);

        const address = {
            '_line1' : _line1,
            '_line2' : _line2,
            '_city' : _city,
            '_state' : _state,
            '_zip' : _zip,
            '_zip_suffix' : _zip_suffix
        }

        await axios
            .post(`${process.env.REACT_APP_API_DOMAIN}/api/address/${type}`, address, {
                withCredentials: true,
                headers: {
                    Authorization: session_id
                }
            })
            .then(() => {
                navigate('/account/');
            })
            .catch((error) => {
                setDisabled(false);

                if(error.response && error.response && error.response.data) {
                    setErrors(error.response.data.notifications);
                } else {
                    setErrors([{'message': 'An error occurred changing your address. Please try again','link': ''}]);
                }
            });
        setLoading(false);
    };

    function Message(props) {
        return <div  className="alert alert-danger"><FontAwesomeIcon icon={faTriangleExclamation} className='menuIcon' />{DOMPurify.sanitize(props.message)}</div>;
    }

    const errorMessages =
        errors.map((error, k) => <Message message={error} key={k} />);




    return (
        <div className='container-fluid'>
            <div className="row">
                <div className='col-md-12'>
                    <PageHeader />
                </div>
            </div>
            <div className="row">
                <div className='col-md-2'>
                    <Menu2 />
                </div>
                <div className='col-md-10 pageContent'>
                    <div className="row">
                        <div className="row shadow col-md-11 ml-3 mb-3 mr-3 bg-white shadow rounded">
                            <div className="breadcrumb"><Link to={"/portal"}>Home</Link> > <Link to={"/account"}>Account</Link> > Update Address</div>
                            <p>
                                <div>{errorMessages}</div>
                                <div className="px-2">
                                    <form  onSubmit={onSubmit} className="justify-content-center">
                                        <SpinnerComponent loading={loading} position="global" color="#498DFF" message={"Sit tight"}/>
                                        <h4>Update Address</h4>


                                        <div className="row form-group">
                                            <div className="col-xl-5">
                                                <label>Street Address</label>
                                            </div>
                                            <div className="col-xl-7">
                                                <input type="text" className="form-control"  onChange={e => setLine1(e.target.value)}  disabled={isDisabled} maxLength="100" value={_line1}/>
                                            </div>
                                        </div>

                                        <div className="row form-group">
                                            <div className="col-xl-5">
                                                <label>Address Line 2</label>
                                            </div>
                                            <div className="col-xl-7">
                                                <input type="text" className="form-control"  onChange={e => setLine2(e.target.value)}  disabled={isDisabled}  maxLength="100" value={_line2}/>
                                            </div>
                                        </div>

                                        <div className="row form-group">
                                            <div className="col-xl-5">
                                                <label>City</label>
                                            </div>
                                            <div className="col-xl-7">
                                                <input type="text" className="form-control"  onChange={e => setCity(e.target.value)}  disabled={isDisabled} maxLength="50" value={_city}/>
                                            </div>
                                        </div>


                                        <div className="row form-group">
                                            <div className="col-xl-5">
                                                <label>State</label>
                                            </div>
                                            <div className="col-xl-7">
                                                <select  className="form-control"  onChange={e => setState(e.target.value)}  disabled={isDisabled} value={_state}>
                                                    <option value=""></option>
                                                    <option value="AL">Alabama</option>
                                                    <option value="AK">Alaska</option>
                                                    <option value="AZ">Arizona</option>
                                                    <option value="AR">Arkansas</option>
                                                    <option value="CA">California</option>
                                                    <option value="CO">Colorado</option>
                                                    <option value="CT">Connecticut</option>
                                                    <option value="DE">Delaware</option>
                                                    <option value="DC">District Of Columbia</option>
                                                    <option value="FL">Florida</option>
                                                    <option value="GA">Georgia</option>
                                                    <option value="HI">Hawaii</option>
                                                    <option value="ID">Idaho</option>
                                                    <option value="IL">Illinois</option>
                                                    <option value="IN">Indiana</option>
                                                    <option value="IA">Iowa</option>
                                                    <option value="KS">Kansas</option>
                                                    <option value="KY">Kentucky</option>
                                                    <option value="LA">Louisiana</option>
                                                    <option value="ME">Maine</option>
                                                    <option value="MD">Maryland</option>
                                                    <option value="MA">Massachusetts</option>
                                                    <option value="MI">Michigan</option>
                                                    <option value="MN">Minnesota</option>
                                                    <option value="MS">Mississippi</option>
                                                    <option value="MO">Missouri</option>
                                                    <option value="MT">Montana</option>
                                                    <option value="NE">Nebraska</option>
                                                    <option value="NV">Nevada</option>
                                                    <option value="NH">New Hampshire</option>
                                                    <option value="NJ">New Jersey</option>
                                                    <option value="NM">New Mexico</option>
                                                    <option value="NY">New York</option>
                                                    <option value="NC">North Carolina</option>
                                                    <option value="ND">North Dakota</option>
                                                    <option value="OH">Ohio</option>
                                                    <option value="OK">Oklahoma</option>
                                                    <option value="OR">Oregon</option>
                                                    <option value="PA">Pennsylvania</option>
                                                    <option value="RI">Rhode Island</option>
                                                    <option value="SC">South Carolina</option>
                                                    <option value="SD">South Dakota</option>
                                                    <option value="TN">Tennessee</option>
                                                    <option value="TX">Texas</option>
                                                    <option value="UT">Utah</option>
                                                    <option value="VT">Vermont</option>
                                                    <option value="VA">Virginia</option>
                                                    <option value="WA">Washington</option>
                                                    <option value="WV">West Virginia</option>
                                                    <option value="WI">Wisconsin</option>
                                                    <option value="WY">Wyoming</option>
                                                </select>
                                            </div>
                                        </div>


                                        <div className="row form-group">
                                            <div className="col-xl-5">
                                                <label>Zip + 4</label>
                                            </div>
                                            <div className="col-xl-4">
                                                <input type="text" className="form-control"  onChange={e => setZip(e.target.value)}  disabled={isDisabled}  maxLength="5" size="5" value={_zip}/>
                                            </div>
                                            <div className="col-xl-3">
                                                <input type="text" className="form-control"  onChange={e => setZipSuffix(e.target.value)}  disabled={isDisabled}  maxLength="4"  size="4" value={_zip_suffix}/>
                                            </div>
                                        </div>


                                        <div className="row text-white d-flex justify-content-center">
                                            <button type="submit" className="btn btn-primary btn-lg" disabled={isDisabled}>Change</button>
                                        </div>
                                    </form>
                                </div>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className='col-md-12'>
                    <Footer />
                </div>
            </div>
        </div>
    );
}
export default AddressEdit;


