import React, {useEffect, useState} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from 'react-tooltip';
import { faIdBadge } from "@fortawesome/free-solid-svg-icons";
import { Link, useNavigate} from 'react-router-dom';
import axios from "axios";
import Cookies from "universal-cookie";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";


const PageHeader = () => {
    const [user, setUser] = useState({});
    const navigate = useNavigate();

    useEffect(() => {

        const cookies = new Cookies();
        const session_id = cookies.get('session_id');

        if (!session_id) {
            navigate('/login');
            return;
        }
        (async () => {
            await axios
                .get(`${process.env.REACT_APP_API_DOMAIN}/api/session/` + session_id, { withCredentials: true })
                .then((res) => {
                    setUser(res.data);
                })
                .catch(function () {
                    navigate('/login');
                });
        })();
    }, [navigate]);



    return (
        <div className='container-fluid'>
            <div className="row shadow p-0 mb-2 bg-white rounded panel">
                <div className='col-md-2'>
                    <Link to={`/portal`}><img src="/img/agencynext.png" className="agencyLogo" alt="Agency Next Logo" /></Link>
                </div>
                <div className='col-md-7'>
                    {(() => {
                        if (process.env.REACT_APP_ENVIRONMENT !== 'production'){
                            return <span className="text-secondary" style={{color: '#094EA3'}}>[{process.env.REACT_APP_ENVIRONMENT}]</span>
                        }
                    })()}
                </div>
                <div className='col-md-3 headerIcons'>
                    <Tooltip id="icon-tooltip-header" />

                    <span className="headerIconWrapper">
                        <FontAwesomeIcon icon={faIdBadge} id="headerAccountIcon" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" className='headerIcon'  data-tooltip-id="icon-tooltip-header" data-tooltip-content="Account" data-tooltip-place="top" />
                        <div className="dropdown-menu" aria-labelledby="headerAccountIcon">
                            <Link to={`/account`}>Account</Link><br/>
                            <Link to={`/logout`}>Log Out</Link>
                        </div>
                    </span>

                    {user._first} {user._last}

                </div>
            </div>
        </div>
    );

}
export default PageHeader;
