import {Link} from "react-router-dom";
import DOMPurify from "dompurify";
import React from "react";

const RssPost = (props) => {

    const post = props.data;

    return (
        <li className="rssPost">
                    <span>[<Link to={`/news-sources/${post._site}`} target="_blank">{DOMPurify.sanitize(post._sitename)}</Link>]&nbsp;&nbsp;
                        <Link to={DOMPurify.sanitize(post._url)} target="_blank">{DOMPurify.sanitize(post._title)}</Link><br />
                        <small>Posted: {post._published_date} {DOMPurify.sanitize(post._url)}</small></span>
        </li>
    );

};


export default RssPost;
