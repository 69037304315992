import React, {useEffect, useState} from 'react';
import { Link } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';
import axios from 'axios';
import DOMPurify from "dompurify";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { faCircleUser } from "@fortawesome/free-solid-svg-icons";
import { faMessage } from "@fortawesome/free-solid-svg-icons";
import { faChartLine } from "@fortawesome/free-solid-svg-icons";
import { faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import { faFaceGrinStars } from "@fortawesome/free-solid-svg-icons";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { faImage } from "@fortawesome/free-solid-svg-icons";
import { faGear } from "@fortawesome/free-solid-svg-icons";
import { faPhone } from "@fortawesome/free-solid-svg-icons";
import { faMailchimp } from "@fortawesome/free-brands-svg-icons";
import { faSalesforce } from "@fortawesome/free-brands-svg-icons";
import { faBook } from "@fortawesome/free-solid-svg-icons";
import { faHeading } from "@fortawesome/free-solid-svg-icons";
import { faSocks } from "@fortawesome/free-solid-svg-icons";
import { faPuzzlePiece } from "@fortawesome/free-solid-svg-icons";
import { faScroll } from "@fortawesome/free-solid-svg-icons";
import { faPalette } from "@fortawesome/free-solid-svg-icons";
import { faPaintRoller } from "@fortawesome/free-solid-svg-icons";

const Menu2 = (props) => {
    const [categories, setCategories] = useState([]);

    useEffect(() => {
        const fetchData = async () => {

            const categoryData = await axios
                .get(`${process.env.REACT_APP_API_DOMAIN}/api/page`, { withCredentials: true });
            setCategories(categoryData.data);
        };
        void fetchData();
    }, []);

    function Category(props) {
        return <ul className="menu"><li className="menuRow"><div className="menuHeading row border-top">{DOMPurify.sanitize(props.title)}</div></li>{props.pages.map((page, k) => <Page title={page.title} url={page.url} icon={page.icon} class={page.class} viewBox={page.viewBox} helpText={page.helpText} pageType={page.pageType} id={page.id}  key={k} />)}</ul>;
    }

    function Page(props) {

        const possibleIcons = {
            magnifyingGlass: faMagnifyingGlass,
            circleUser: faCircleUser,
            message: faMessage,
            chartLine: faChartLine,
            penToSquare: faPenToSquare,
            grinStars: faFaceGrinStars,
            bars: faBars,
            image: faImage,
            gear: faGear,
            phone: faPhone,
            mailchimp: faMailchimp,
            salesforce: faSalesforce,
            book: faBook,
            heading: faHeading,
            socks: faSocks,
            puzzle: faPuzzlePiece,
            scroll: faScroll,
            palette: faPalette,
            paintRoller: faPaintRoller
        }

        let url = '';

        if(props.pageType === 'iframe') {
            url = '/page/' + props.id;
        } else if(props.pageType === 'external') {
            url = props.url;
        } else {
            url = '/' + props.url;
        }

        let target = '_self';
        if(props.pageType === 'external') {
            target = '_blank';
        }


        return  <li>
            <div className="menuLink row">
                <Link to={url} target={target}>
                    <FontAwesomeIcon icon={possibleIcons[props.icon]}  className='menuIcon'  data-tooltip-id="icon-tooltip-menu" data-tooltip-content={props.helpText} data-tooltip-place="top" />
                    {DOMPurify.sanitize(props.title)}
                </Link>
            </div>
        </li>;
    }

    const menu =
        categories.map((category, k) => <Category title={category.title} pages={category.pages} key={k} />);

    return (
        <div className='container-fluid'>
            <div className="row  shadow p-0 mb-2 bg-white rounded">
                <div className='col-md-12'>
                    <Tooltip id="icon-tooltip-menu" />
                    {menu}
                </div>
            </div>
        </div>
    );

}
export default Menu2;

