import {useEffect} from 'react';
import {useParams} from 'react-router-dom';
import Cookies from "universal-cookie";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";

function Impersonate() {
    const { id } = useParams();

    useEffect( () => {
        async function updateCookie() {
            const cookies = new Cookies();
            await cookies.remove('session_id', {path: '/', domain: process.env.REACT_APP_EXPERIMENT_COOKIE_DOMAIN});
            await cookies.set('session_id', id, {path: '/', domain: process.env.REACT_APP_EXPERIMENT_COOKIE_DOMAIN});
        }

        updateCookie();
        window.location.href = '/portal';

    }, [id]);
}
export default Impersonate;
