import React, {useEffect, useState} from 'react';
import '../App.css';
import Footer from './Footer';
import PageHeader from './PageHeader';
import Menu2 from './Menu2';
import {Link, useNavigate, useParams} from "react-router-dom";
import axios from "axios";
import Cookies from "universal-cookie";
import { SpinnerComponent } from 'react-element-spinner';


function Invoice() {
    const [invoice, setInvoice] = useState([]);
    const navigate = useNavigate();
    const { id } = useParams();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const cookies = new Cookies();
        const session_id = cookies.get('session_id');

        const fetchData = async () => {
            setLoading(true);
            const invoiceData = await axios
                .get(`${process.env.REACT_APP_API_DOMAIN}/api/invoice/${id}`, {
                    withCredentials: true,
                    headers: {
                        Authorization: session_id
                    }
                });
            setInvoice(invoiceData.data);
            setLoading(false);
        };
        void fetchData();

    }, [navigate, id]);

    function InvoiceLine(props) {
        return <div>{props.description}</div>
    }

    function Invoice(props) {
        console.log(props.effective);
        const invoiceDate = new Date(props.effective * 1000).toLocaleDateString("en-US");

        const total = (props.total / 100).toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD',
        });

        const paid = props.paid ? 'Yes' : 'No';

        const description = props.lines.map((line) => <InvoiceLine description={line.description} />);

        return <tr><td>{invoiceDate}</td><td>{props.number}</td><td>{description}</td><td>{total}</td><td>{paid}</td><td><Link to={props.pdf} target="_blank">Link</Link></td></tr>
    }

    const rows =
        invoice.map((invoice, k) => <Invoice effective={invoice.effective_at} total={invoice.total} number={invoice.number} paid={invoice.paid} pdf={invoice.invoice_pdf} lines={invoice.lines}  key={k} />);


    return (
        <div className='container-fluid'>
            <div className="row">
                <div className='col-md-12'>
                    <PageHeader />
                </div>
            </div>
            <div className="row">
                <div className='col-md-2'>
                    <Menu2 />
                </div>
                <div className='col-md-10 pageContent'>
                    <div className="row">
                        <div className="row shadow col-md-11 ml-3 mb-3 mr-3 bg-white shadow rounded">
                            <SpinnerComponent loading={loading} position="global" color="#498DFF" message={"Sit tight"}/>
                            <div className="breadcrumb"><Link to={"/portal"}>Home</Link> > Invoices > Invoice </div>
                            <p>
                                <h4>Invoice</h4>
                                <table className="table">
                                    <tr>
                                        <th scope="col">
                                            Date
                                        </th>
                                        <th scope="col">
                                            Number
                                        </th>
                                        <th scope="col">
                                            Description
                                        </th>
                                        <th scope="col">
                                            Amount
                                        </th>
                                        <th scope="col">
                                            Paid?
                                        </th>
                                        <th scope="col">
                                            Download
                                        </th>
                                    </tr>
                                    {rows}
                                </table>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className='col-md-12'>
                    <Footer />
                </div>
            </div>
        </div>
    );
}
export default Invoice;

