import React, {useEffect, useState} from 'react';
import '../../App.css';
import Footer from '../Footer';
import PageHeader from '../PageHeader';
import Menu2 from '../Menu2';
import {Link, useNavigate} from "react-router-dom";
import Cookies from "universal-cookie";
import axios from "axios";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTriangleExclamation} from "@fortawesome/free-solid-svg-icons";
import DOMPurify from "dompurify";
import { PatternFormat } from 'react-number-format';
import { SpinnerComponent } from 'react-element-spinner';

function ProfileEdit() {

    const [_first, setFirst] = useState('');
    const [_last, setLast] = useState('');
    const [_email, setEmail] = useState('');
    const [_phone, setPhone] = useState('');
    const [_phone_extension, setPhoneExtension] = useState('');
    const [_cell, setCell] = useState('');
    const [_timezone, setTimezone] = useState('');

    const [isDisabled, setDisabled] = useState();
    const navigate = useNavigate();
    const cookies = new Cookies();
    const session_id = cookies.get('session_id');
    const [errors, setErrors] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {

        const cookies = new Cookies();
        const session_id = cookies.get('session_id');
        const fetchData = async () => {
            setLoading(true);
            const profileData = await axios
                .get(`${process.env.REACT_APP_API_DOMAIN}/api/user`, {
                    withCredentials: true,
                    headers: {
                        Authorization: session_id
                    }
                });

            setFirst(profileData.data._first);
            setLast(profileData.data._last);
            setEmail(profileData.data._email);
            setPhone(profileData.data._phone);
            setPhoneExtension(profileData.data._phone_extension);
            setCell(profileData.data._cell);
            setTimezone(profileData.data._timezone);

            setLoading(false);
        };
        void fetchData();

    }, []);


    const onSubmit = async (e) => {
        setLoading(true);
        e.preventDefault();
        setDisabled(true);

        const profile = {
            '_first' : _first,
            '_last' : _last,
            '_email' : _email,
            '_phone' : _phone,
            '_phone_extension' : _phone_extension,
            '_cell' : _cell,
            '_timezone' : _timezone
        }

        await axios
            .post(`${process.env.REACT_APP_API_DOMAIN}/api/user/`, profile, {
                withCredentials: true,
                headers: {
                    Authorization: session_id
                }
            })
            .then(() => {
                navigate('/account/');
            })
            .catch((error) => {
                setDisabled(false);

                if(error.response && error.response && error.response.data) {
                    setErrors(error.response.data.notifications);
                } else {
                    setErrors([{'message': 'An error occurred updating your profile. Please try again','link': ''}]);
                }
            });
        setLoading(false);
    };

    function Message(props) {
        return <div  className="alert alert-danger"><FontAwesomeIcon icon={faTriangleExclamation} className='menuIcon' />{DOMPurify.sanitize(props.message)}</div>;
    }

    const errorMessages =
        errors.map((error, k) => <Message message={error} key={k} />);

    return (
        <div className='container-fluid'>
            <div className="row">
                <div className='col-md-12'>
                    <PageHeader />
                </div>
            </div>
            <div className="row">
                <div className='col-md-2'>
                    <Menu2 />
                </div>
                <div className='col-md-10 pageContent'>
                    <div className="row">
                        <div className="row shadow col-md-11 ml-3 mb-3 mr-3 bg-white shadow rounded">
                            <div className="breadcrumb"><Link to={"/portal"}>Home</Link> > <Link to={"/account"}>Account</Link> > Edit</div>
                            <p>
                                <div>{errorMessages}</div>



                                <div className="px-2">
                                    <form  onSubmit={onSubmit} className="justify-content-center">
                                        <SpinnerComponent loading={loading} position="global" color="#498DFF" message={"Sit tight"}/>
                                        <h4>Update Personal Information</h4>


                                        <div className="row form-group">
                                            <div className="col-xl-5">
                                                <label>First Name</label>
                                            </div>
                                            <div className="col-xl-7">
                                                <input type="text" className="form-control"  onChange={e => setFirst(e.target.value)}  disabled={isDisabled} maxLength="32" value={_first}/>
                                            </div>
                                        </div>



                                        <div className="row form-group">
                                            <div className="col-xl-5">
                                                <label>Last Name</label>
                                            </div>
                                            <div className="col-xl-7">
                                                <input type="text" className="form-control"  onChange={e => setLast(e.target.value)}  disabled={isDisabled} maxLength="32" value={_last}/>
                                            </div>
                                        </div>

                                        <div className="row form-group">
                                            <div className="col-xl-5">
                                                <label>Email Address</label>
                                            </div>
                                            <div className="col-xl-7">
                                                <input type="text" className="form-control"  onChange={e => setEmail(e.target.value)}  disabled={isDisabled} maxLength="256" value={_email}/>
                                            </div>
                                        </div>

                                        <div className="row form-group">
                                            <div className="col-xl-5">
                                                <label>Office Phone (+ Extension)</label>
                                            </div>
                                            <div className="col-xl-4">
                                                <PatternFormat format="+1 (###) ### ####" allowEmptyFormatting mask="_" className="form-control"  onChange={e => setPhone(e.target.value)} disabled={isDisabled} value={_phone}  />
                                            </div>
                                            <div className="col-xl-3">
                                                <input type="text" className="form-control"  onChange={e => setPhoneExtension(e.target.value)}  disabled={isDisabled} maxLength="6" value={_phone_extension}/>
                                            </div>
                                        </div>

                                        <div className="row form-group">
                                            <div className="col-xl-5">
                                                <label>Mobile Phone</label>
                                            </div>
                                            <div className="col-xl-7">
                                                <PatternFormat format="+1 (###) ### ####" allowEmptyFormatting mask="_" className="form-control"  onChange={e => setCell(e.target.value)} disabled={isDisabled} value={_cell}  />
                                            </div>
                                        </div>

                                        <div className="row form-group">
                                            <div className="col-xl-5">
                                                <label>Timezone</label>
                                            </div>
                                            <div className="col-xl-7">
                                                <select className="form-control"  onChange={e => setTimezone(e.target.value)}  disabled={isDisabled} value={_timezone}>
                                                    <option value="Pacific/Honolulu">Pacific/Honolulu GMT-10:00</option>
                                                    <option value="America/Anchorage">America/Anchorage GMT-9:00</option>
                                                    <option value="America/Los_Angeles">America/Los_Angeles GMT-8:00</option>
                                                    <option value="America/Boise">America/Boise GMT-7:00</option>
                                                    <option value="America/Denver">America/Denver GMT-7:00</option>
                                                    <option value="America/Phoenix">America/Phoenix GMT-7:00</option>
                                                    <option value="America/Chicago">America/Chicago GMT-6:00</option>
                                                    <option value="America/Detroit">America/Detroit GMT-5:00</option>
                                                    <option value="America/New_York">America/New_York GMT-5:00</option>
                                                </select>
                                            </div>
                                        </div>





                                        <div className="row text-white d-flex justify-content-center">
                                            <button type="submit" className="btn btn-primary btn-lg" disabled={isDisabled}>Change</button>
                                        </div>
                                    </form>
                                </div>




                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className='col-md-12'>
                    <Footer />
                </div>
            </div>
        </div>
    );
}
export default ProfileEdit;


