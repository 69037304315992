import React from 'react';
import '../App.css';
import {Link} from 'react-router-dom';
import CookieConsent from "react-cookie-consent";

function Confirmation() {

    return (
        <section id="cover" className="min-vh-100">
            <div id="cover-caption">
                <div className="container">
                    <div className="row text-white">
                        <div className="col-xl-5 col-lg-6 col-md-8 col-sm-10 mx-auto text-center form p-4">
                            <img src="/img/agencynext.png" className="agencyLogo" alt="Agency Next Company Logo"/>
                            <div className="px-2">
                                <form className="justify-content-center">
                                    <div className="form-group">
                                        Your password has been changed. The new password was sent to your email address. Please use it to <Link to={`/login`} className="text-white">Log in</Link>.
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <CookieConsent>This website uses cookies to enhance the user experience.</CookieConsent>
        </section>
    );
}

export default Confirmation;

